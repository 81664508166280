import React from "react";
import "./App.css";
import GridLanding from "./Components/GridLanding";
import Header from "./Components/Header";

const App = () => {
  return (
    <>
      <Header />
      <GridLanding />
    </>
  );
};

export default App;
