import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
  IconButton,
  Alert,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Grid from "@mui/material/Unstable_Grid2";
import Tesseract from "tesseract.js";
import { useState } from "react";
import axios from "axios";

import Loading from "./Loading";

function GridLanding() {
  //typography Style
  var theme = createTheme({
    typography: {
      h3: {
        fontSize: "3rem",
      },
      h4: {
        fontSize: "1.125rem",
      },
      h5: {
        fontSize: "1rem",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  //Lógica do UploadButton
  const [removeLoading, setRemoveLoading] = useState(true);
  var [inputText, setInputText] = useState();

  const submition = async (e) => {
    console.log("DFS");
    let file = e.target.files[0];
    setRemoveLoading(false);

    await Tesseract.recognize(file, "eng", { logger: (m) => console.log(m) })
      .then(({ data: { text } }) => {
        setInputText(text);
        console.log(inputText);
        setRemoveLoading(true);
        e.target.value = null;
      })
      .catch((err) => console.log(err));
  };

  //Requisições da OpenAI
  const [userQuestion, setUserQuestion] = useState("");
  const [question, setQuestion] = useState("");
  const [removeLoadingQuizz, setRemoveLoadingQuizz] = useState(true);
  const [btnName, setBtnName] = useState("Generate Question");
  const [errorMsg, setErrorMsg] = useState(false);
  const [textField, setTextField] = useState(false);

  const handleGetResponse1 = async () => {
    setRemoveLoadingQuizz(false);

    try {
      const pergunta = await axios.post("https://api.ratti.com.br/question", {
        inputQuestion: userQuestion,
      });

      setRemoveLoadingQuizz(true);
      setBtnName("Generate More Questions");
      setErrorMsg(false);
      setQuestion(pergunta.data);
      setTextField(true);
      setCorrectionBtn(false);
      console.log(pergunta.data);
    } catch (error) {
      console.log("Erro ao chamar a API:", error);
      setRemoveLoadingQuizz(true);
      setErrorMsg(true);
      setQuestion();
    }
  };

  //Requisição de correção de resposta
  const [userResponse, setUserResponse] = useState("");
  const [response, setResponse] = useState("");
  const [validationBtn, setValidationBtn] = useState(false);
  const [correctionBtn, setCorrectionBtn] = useState(false);
  const [errorMsg2, setErrorMsg2] = useState(false);
  var resp;

  //req para avaliar se resposta esta certa ou nao
  const reqValidation = async () => {
    setRemoveLoadingQuizz(false);

    try {
      const response = await axios.post("https://api.ratti.com.br/correction", {
        inputQuestion: userQuestion,
        userResp: userResponse,
      });

      console.log(response, "response");
      setRemoveLoadingQuizz(true);
      setErrorMsg2(false);
      await setResponse(response.data);
      resp = response.data;
      console.log(response.data, "response");

      if (resp === "Incorreto.") {
        setCorrectionBtn(true);
      }
    } catch (error) {
      console.log("Erro ao chamar a API:", error);
      setRemoveLoadingQuizz(true);
      setErrorMsg2(true);
      setUserQuestion();
    }
  };

  //req pra corrigir a resposta
  const reqCorrection = async () => {
    setRemoveLoadingQuizz(false);

    try {
      const response = await axios.post("https://api.ratti.com.br/answer", {
        inputQuestion: userQuestion,
        userResp: userResponse,
      });

      setRemoveLoadingQuizz(true);
      setErrorMsg2(false);
      setResponse(response.data);
      console.log(response, "response");
    } catch (error) {
      console.log("Erro ao chamar a API:", error);
      setRemoveLoadingQuizz(true);
      setErrorMsg2(true);
      setUserResponse();
    }
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item="true" xs={12} sm={6} sx={{ p: 4 }} textAlign={"center"}> {/* Titulo da pagina */}
          {" "}
          <ThemeProvider theme={theme}>
            <Typography variant="h3" color={"primary"}>
              Welcome to QueryScribe!
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 1 }} textAlign={"center"}>  {/* Texto em cima do botao */}
          {" "}
          <ThemeProvider theme={theme}>
            <Typography variant="h4">
              Import your text file where you want to derive your questions:
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 1 }} textAlign={"center"}>  {/* Botao upload */}
          {" "}
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            onInput={submition}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCameraIcon />
            </IconButton>
          </label>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 3 }}> {/* Loading */}
          {" "}
          {!removeLoading && <Loading />}
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 3 }}> {/* Mensagem Erro API */}
          {" "}
          {errorMsg && <Alert severity="error">API Error</Alert>}
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 3 }}> {/* TextField */}
          {" "}
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={6}
            placeholder="Text image"
            fullWidth
            value={inputText}
            onChange={(e) => {
              setUserQuestion(e.target.value);
              setBtnName("Generate Question");
            }}
          />
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 1 }} textAlign={"center"}> {/* Texto em cima do botao */}
          {" "}
          <ThemeProvider theme={theme}>
            <Typography variant="h4">Configure your questions:</Typography>
          </ThemeProvider>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 1 }} textAlign={"center"}> {/* Botao gerador de perguntas e corretor de resposta*/}
          {" "}
          <label>
            <Button variant="contained" onClick={handleGetResponse1}>
              {btnName}
            </Button>
          </label>
          {"  "} {/* espaçamento entre botoes */}
          <label>
            <Button
              variant="contained"
              disabled={!validationBtn}
              onClick={reqValidation}
            >
              Correction Button
            </Button>
          </label>
          {"  "} {/* espaçamento entre botoes */}
          <label>
            <Button
              variant="contained"
              disabled={!correctionBtn}
              onClick={reqCorrection}
            >
              Answer Button
            </Button>
          </label>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 3 }}> {/* Loading */}
          {" "}
          {!removeLoadingQuizz && <Loading />}
        </Grid>
        <Grid item="true" xs={6} sm={3} sx={{ p: 3 }}> {/* paragrafo de resposta do bot */}
          {" "}
          <ThemeProvider theme={theme}>
            <Typography variant="h5">
              {question}
              <br />
              <br />
              {(response === "Correto." && (
                <Alert severity="success">{response}</Alert>
              )) ||
                (response === "Incorreto." && (
                  <Alert severity="error">{response}</Alert>
                )) ||
                (response && <Alert severity="info">{response}</Alert>) ||
                (errorMsg2 && <Alert severity="error">API Error</Alert>)}
              <br />
              <br />
              {question && "Write the answer below:"}
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item="true" xs={12} sm={6} sx={{ p: 3 }}> {/* TextField */}
          {" "}
          { textField && <TextField
            id="outlined-multiline-static"
            multiline
            rows={6}
            fullWidth
            onChange={(e) => {
              setUserResponse(e.target.value);
              setValidationBtn(true);
            }}
          />}
        </Grid>
      </Grid>
    </Box>
  );
}
export default GridLanding;
